@import 'variables';

// fix wrong row margins
.row {
  margin-left: -10px !important;
  margin-right: -10px !important;
}

.content {
  overflow-x: clip;
}

.min-h-100vh {
  min-height: 100vh;
}

@mixin map-icon-base {
  background-size: 29px;
  text-align: center;
  color: #fff;
}

.block-message {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  backdrop-filter: blur(3px);
  z-index: 100;
  font-size: 3vw;
  color: black;
  text-align: center;

  &-text {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: rgba(#4b4b4b, 0.25);
    padding: 20px 40px;
    border-radius: 15px;
    width: 65%;
  }
}

.whatsapp-widget {
  position: fixed;
  right: 40px;
  bottom: 40px;
}

.highlight { background-color: yellow; }

.caption-extand {
  width: 100%
}

label.error {
  background-color: #f9b1c2;
  padding: 4px !important;
  margin-top: 4px;
  margin-right: 5px;
  border-radius: 3px;
  font-style: italic;
}

.tooltip-inner {
  text-align: right;
}

.table-hover > tbody > tr:hover > td.danger {
  background-color: #ff6672 !important;
}

.cursor-grab {
  cursor: grab;
}

/* add drag cursor to dragula */
.gu-mirror {
  margin: 10px;
  width: 100%;
  padding: 10px;;
  background-color: rgba(0, 0, 0, 0.2);
  transition: all 0.4s ease-in-out;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* visit visuliaztion baloons*/
.mt-element-step .step-line .return .mt-step-number {
  color: #8e44ad !important;
  border-color: #8e44ad !important;
}
.mt-element-step .step-line .return .mt-step-title,
.mt-element-step .step-line .return .mt-step-content {
  color: #8e44ad !important;
}
.mt-element-step .step-line .return .mt-step-title:after, .mt-element-step .step-line .return .mt-step-title:before {
  background-color: #8e44ad;
}

/* for select2 on modal */
.select2-dropdown.increasedzindexclass {
  z-index: 20000;
}

.datepicker-dropdown {
  z-index: 20000!important;
}

/* for printing links */
@media print {
  a[href]:after {
    content: none !important;
  }
}

li.mt-list-item.seen {
  background-color: #f1f1f1 !important;
}

.potential-minus-number{
  direction: ltr;
  text-align: right;
}

.tooltip_templates { display: none; }

.tooltip {
  pointer-events: none;
}

.ltr {
  direction: ltr;
}

.text-nowrap {
  white-space: nowrap;
}

@media (min-width: 576px) {
  .text-sm-nowrap {
    white-space: nowrap;
  }
}

.import-card {
  border: solid;
  border-width: 1px;
  background: #f6fbff;
  border-radius: 12px !important;
  padding: 15px;
  margin: 15px;
  height: 160px;
}

.failed-task-big {
  color: #000 !important;
  background-color: #ac7274 !important;
  border-color: #ac7274 !important;
  i {
    color: #000;
  }
}
.final-failed-task-big {
  color: #fff !important;
  background-color: #000 !important;
  border-color: #000 !important;
  i {
    color: #fff;
  }
  a, a:hover {
    color: #fff;
  }
  span {
    color: #fff !important;
  }
}
.in-transfer-task-big {
  color: #000 !important;
  background-color: #f70268 !important;
  border-color: #f70268 !important;
  i {
    color: #000;
  }
  a, a:hover {
    color: #000;
  }
  span {
    color: #000 !important;
  }
}
.unassigned-task-big {
  color: #000 !important;
  background-color: $unassigned-status-color !important;
  border-color: $unassigned-status-color !important;
  i {
    color: #000;
  }
}
.assigned-task-big {
  color: #000 !important;
  background-color: $assigned-status-color !important;
  border-color: $assigned-status-color !important;
  i {
    color: #000;
  }
}
.active-task-big {
  color: #000 !important;
  background-color: $active-status-color !important;
  border-color: $active-status-color !important;
  i {
    color: #000;
  }
}
.completed-task-big {
  color: #fff !important;
  background-color: $completed-status-color !important;
  border-color: $completed-status-color !important;
  i {
    color: #fff;
  }
}
.roundtrip-delivered-task-big {
  color: #fff !important;
  background-color: $roundtrip-delivered-status-color !important;
  border-color: $roundtrip-delivered-status-color !important;
  i {
    color: #fff;
  }
}
.canceled-task-big {
  color: #000 !important;
  background-color: $canceled-status-color !important;
  border-color: $canceled-status-color !important;
  i {
    color: #000;
  }
}
.in-inventory-task-big {
  color: #000 !important;
  background-color: $in-inventory-status-color !important;
  border-color: $in-inventory-status-color !important;
  i {
    color: #000;
  }
}
.out-inventory-task-big {
  color: #000 !important;
  background-color: $out-inventory-status-color !important;
  border-color: $out-inventory-status-color !important;
  i {
    color: #000;
  }
}

.unassigned-task {
  color: #000 !important;
  background-color: $unassigned-status-color !important;
  border-color: $unassigned-status-color !important;

  i { color: #000 !important; }
}
.assigned-task {
  color: #000 !important;
  background-color: $assigned-status-color !important;
  border-color: $assigned-status-color !important;

  i { color: #000 !important; }
}
.active-task {
  color: #000 !important;
  background-color: $active-status-color !important;
  border-color: $active-status-color !important;

  i { color: #000 !important; }
}
.failed-task {
  color: #fff !important;
  background-color: $failed-status-color !important;
  border-color: $failed-status-color !important;

  i { color: #fff !important; }
}
.final-failed-task {
  color: #fff !important;
  background-color: $final-failed-status-color !important;
  border-color: $final-failed-status-color !important;

  i { color: #fff !important; }
}
.in-transfer-task {
  color: #000 !important;
  background-color: $in-transfer-status-color !important;
  border-color: $in-transfer-status-color !important;

  i { color: #000 !important; }
}
.completed-task {
  color: #fff !important;
  background-color: $completed-status-color !important;
  border-color: $completed-status-color !important;

  i { color: #fff !important; }
}
.roundtrip-delivered-task {
  color: #fff !important;
  background-color: $roundtrip-delivered-status-color !important;
  border-color: $roundtrip-delivered-status-color !important;

  i { color: #fff !important; }
}
.canceled-task {
  color: #000 !important;
  background-color: $canceled-status-color !important;
  border-color: $canceled-status-color !important;

  i { color: #000 !important; }
}
.in-inventory-task {
  color: #000 !important;
  background-color: $in-inventory-status-color !important;
  border-color: $in-inventory-status-color !important;

  i { color: #000 !important; }
}
.out-inventory-task {
  color: #000 !important;
  background-color: $out-inventory-status-color !important;
  border-color: $out-inventory-status-color !important;

  i { color: #000 !important; }
}

.status-option {
  width:85px; padding: 7px; height: 26px;
  cursor: pointer;
}

.driver-option {
  padding: 7px; height: 26px;
}

.select2-results__option {
  min-height: 2em;
}

.red-number-icon {
  background-image: url('../../../public/map-icon-red.png');
  @include map-icon-base;
}

.blue-number-icon {
  background-image: url('../../../public/map-icon-blue.png');
  @include map-icon-base;
}

.brown-number-icon {
  background-image: url('../../../public/map-icon-brown.png');
  @include map-icon-base;
}

.driver-icon {
  background-image: url('../../../public/driver-live-icon.png');
  background-size: 28px;
  background-repeat: no-repeat;
  z-index: 10000 !important;
}

.red-flag-icon {
  background-image: url('../../../public/red-flag.png');
  background-size: 29px;
  z-index: -10000 !important;
}

.blue-flag-icon {
  background-image: url('../../../public/blue-flag.png');
  background-size: 29px;
  z-index: -10000 !important;
}

.purple-flag-icon {
  background-image: url('../../../public/map-icon-f38fe7.png');
  background-size: 29px;
  z-index: -10000 !important;
}

.small-driver-icon
{
  background-image: url('../../../public/driver-icon.png');
  background-size: 20px;
}

.map-icon-number {
  font-weight: bold;
  padding: 6px 4px;
}

.leaflet-popup-content-wrapper {
  .popup-content {
    display: flex;
    flex-direction: column;
    .title {
      display: flex;
      justify-content: flex-start;
    }
    .first-line {
      margin-bottom: 0;
      display: flex;
      justify-content: space-between;
      span {
        font-weight: 600;
      }
      a {
        margin-right: 30px;
        font-weight: 600;
      }
    }
    .second-line {
      margin-bottom: 0;
      display: flex;
      justify-content: space-between;
      font-style: italic;
      .fa-user {
        margin-left: 3px;
      }
      .fa-phone {
        margin-right: 2px;
      }
      .name {
        margin-left: 10px;
      }
    }
    h6 {
      margin-bottom: 0;
      display: flex;
      justify-content: space-between;
      font-style: italic;
      .fa-calendar {
        margin-left: 3px;
      }
    }
  }
  border-radius: 12px!important;
  opacity: 0.87;
  max-height: 500px;
  overflow-y: auto;
}

.preloader-wrapper {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  display: none;
  .preloader {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 120px;
  }
}

.locale-name {
  color: #c6cfda;
}

.dropdown-menu:not(.datepicker) {
  padding: 0 10px 0 6px;
  .search {
    padding: 8px 16px;
  }
  .dropdownCheckBoxDiv {
    white-space: nowrap;
  }

  @media(max-width: 600px) {
    width: auto !important;
  }
}

.clickable-row {
  cursor: pointer;
}

.emphasize {
  font-size: 1.8rem;
  font-weight: bold;
}

@for $i from 10 through 20 {
  .font-size-#{$i} {
    font-size: $i + px;
  }
}

.origin-logo {
  max-height: 30px;
  max-width: 30px;
}

.menu-icon {
  i {
    font-size: 22px;
    transition: color 0.2s ease-out;
  }
}

table.bordered-table {
  td, th {
    border: 1px solid black;
  }
}

body {
  &.public-page-bg {
    //background-color: linear-gradient(135deg, #8363a1 0%, #74a8c3 100%) !important;
    background-color: transparent !important;
    background-image: url("../../../public/public-pages-bg.svg");
    background-position: 50%;
    background-size: cover;
    height: 100vh;
    background-attachment: fixed;
  }
  &.public-custom-bg {
    background: linear-gradient(0, rgba(var(--r), var(--g), var(--b), 0.75), rgba(var(--r), var(--g), var(--b), 1), rgba(var(--r), var(--g), var(--b), 0.75));
  }
}

button, a[type="button"], [type="button"], select {
  -webkit-appearance: none !important;
}

select {
  background: url('../../../public/Arrow-down.svg.png') center right 10px no-repeat;
  background-size: 15px;
}

.new_location {
  .select2-container {
    width: 100% !important;
    height: calc(1.5em + 1.3rem + 2px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3F4254;
    background-clip: padding-box;
    border-radius: 0.42rem;
    box-shadow: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    .select2-selection--single {
      height: calc(1.5em + 1.3rem + 2px);
    }
  }
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #F3F6F9 !important;
  opacity: 1 !important;
}

.form-group {
  .tooltipstered {
    margin-left: 0.4rem;
  }
}

.summary-form {
  .select2-container {
    width: 100% !important;
    height: calc(1.5em + 1.3rem + 2px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3F4254;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #E4E6EF;
    border-radius: 0.42rem;
    box-shadow: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    .select2-selection--single {
      height: calc(1.5em + 1.3rem + 2px);
    }
    .select2-selection__rendered {
      height: calc(1.5em + 1.3rem + 2px);
      line-height: calc(1.5em + 1.3rem + 2px);;
    }
  }
}

.pricing-form {
  .select2-container {
    width: 100% !important;
    height: calc(1.5em + 1.3rem + 2px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3F4254;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #E4E6EF;
    border-radius: 0.42rem;
    box-shadow: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    .select2-selection--single {
      height: calc(1.5em + 1.3rem + 2px);
    }
    .select2-selection__rendered {
      height: calc(1.5em + 1.3rem + 2px);
      line-height: calc(1.5em + 1.3rem + 2px);;
    }
  }
}

.logo {
  @media (max-width: 992px) {
    width: 40px;
  }
}

.header-menu-mobile {
  @media (max-width: 992px) {
    padding: 15px;
  }
}

.aside {
  width: $aside-width !important;

  @media(min-width: 992px) {
    padding-top: 48px;
  }
}

.aside-fixed .wrapper {
  @media (min-width: 992px) {
    padding-left: $aside-width !important;
  }
}

.aside-enabled .header.header-fixed {
  left: 0 !important;
  @media (min-width: 992px) {
    z-index: 98;
  }
}

.aside-enabled.subheader-fixed .subheader {
  left: $aside-width !important;
}

.aside-minimize .header.header-fixed {
  left: 95px;
}

.aside-minimize.subheader-fixed .subheader {
  left: 95px;
}

.aside, .header, .wrapper, .subheader {
  transition: 0.5s ease !important;
}

.subheader {
  box-shadow: 0 10px 30px 0 rgba(82, 63, 105, 0.175) !important;
}

.checkbox.form-group {
  input[type="checkbox"] {
    width: 20px;
    height: 20px;
  }
}
.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple {
  min-height: 38px;
}

#operator-store-visits-table .select2-container--default .select2-selection--single,
#operator-store-visits-table .select2-container--default .select2-selection--multiple {
  min-height: 38px;
}
#operator-store-visits-table .select2-container--default .select2-selection--single .select2-selection__rendered {
  padding: 0.25rem 3rem 0.25rem 1rem;
}
.form-control.form-control-xs {
  height: calc(1em + 1rem + 2px);
  padding: 0.35rem 0.75rem;
  border-radius: 0.28rem;
}

body {
  color: #000;
}

html, body {
  font-size: 13px !important;
}

.form-group {
  label {
    color: #000;
  }
}

.dataTables_wrapper .dataTable th, .dataTables_wrapper .dataTable td {
  color: #000;
}

.visit-row {
  .btn.btn-light-primary {
    width: 80%;
    :hover {
      background-color: aliceblue;
    }
  }
  .checkbox {
    .fa-refresh {
      position: absolute;
      left: 1px;
      top: 3px;
      transform: translate(-50%, -50%);
    }
  }
}

.visits-extended-table-head {
  th {
    border-collapse: collapse;
    &:nth-child(1) {
      border-right: 1px solid #000;
    }
    &:nth-child(2) {
      border-right: 1px solid #000;
    }
    &:nth-child(3) {
      border-right: 1px solid #000;
    }
    &:nth-child(4) {
      border-right: 1px solid #000;
    }
  }
}

.visits-extended-table-head-row {
  th {
    border-collapse: collapse;
    &:nth-child(5) {
      border-right: 1px solid black;
    }
    &:nth-child(11) {
      border-right: 1px solid black;
    }
    &:nth-child(17) {
      border-right: 1px solid black;
    }
    &:nth-child(19) {
      border-right: 1px solid black;
    }
  }
}

.visits-extended-table {
  tbody {
    tr {
      td {
        &:nth-child(5) {
          border-right: 1px solid black;
        }
        &:nth-child(11) {
          border-right: 1px solid black;
        }
        &:nth-child(17) {
          border-right: 1px solid black;
        }
        &:nth-child(19) {
          border-right: 1px solid black;
        }
      }
    }
  }
}

.regions-table td {
  padding: 0 0.75rem;
  .btn.btn-primary {
    width: 140px;
  }
}

.aside-minimize:not(.aside-minimize-hover)  {
  .menu-item.menu-item-heading,
  .menu-item.menu-item-search {
    display: none;
  }
}

.filter-dropdown-max {
  max-height: 45vh;
  overflow-y: auto;
  overflow-x: hidden;
  @media (max-width: 600px) {
    width: 30vw;
  }
}

.dropdown-max {
  max-height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
  @media (max-height: 600px) {
    max-height: 60vh;
  }
}

.dropdown-menu.dropdown-width {
  @media (max-width: 600px) {
    width: 30vw;
  }
}

.driver-route-box {
  min-height: 300px;
}

.dropdown-menu.dropdown-menu-lg {
  &.offcanvas {
    @media (max-width: 425px) {
      width: 290px !important;
    }
  }
}

.history-table {
  border-top: 1px solid #2b2b2f!important;
}

.dropdown-toggle.btn:after {
  position: absolute;
  right: 5px;
  top: 50%;
  margin-top: -4px;
}

.table td {
  vertical-align: middle !important;
  .mt-checkbox {
    margin-top: 0.5rem !important;
  }
}

.dropdowns-group {
  @media (max-width: 600px) {
    flex-direction: column;
    width: 100%;
  }
  .dropdown-menu {
    @media (max-width: 600px) {
      width: 28vw;
    }
  }
}

.datepicker td, .datepicker th {
  text-align: center;
}

.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
  border-radius: 4px;
}

.drivers-select2-container {
  & .select2-results__option.select2-results__option--group {
    padding: 0;
  }

  & li.unassign-option, & li.unassign-option.select2-results__option--highlighted {
    white-space: nowrap;
    background: #f64e60;
    color: #fff !important;
    .fa-light {
      color: #fff !important;
    }
  }

  & li.unassign-option.select2-results__option--highlighted:hover {
    color: #3F4254 !important;
    background: #EBEDF3 !important;
    .fa-light {
      color: #3F4254 !important;
    }
  }
}

.input-icon > i {
  color: #ccc;
  display: block;
  position: absolute;
  margin: 13px 10px 4px 2px;
  z-index: 3;
  width: 16px;
  font-size: 16px;
  text-align: center;
  right: 0;
}

.input-icon .form-control {
  padding-right: calc(1.5em + 1rem);
  padding-left: 1rem;
}

input.form-control,
textarea.form-control,
select.form-control,
span.select2-selection.select2-selection--single,
.input-group-append .input-group-text{
  border-color: #b9b9b9;
}

span.disable-day {
  color: #efefef;
}

.invoice-content-2 {
  padding: 20px 30px !important;

  .invoice-body h3 {
    font-size: 14px;
    font-weight: 600;
    color: #4e5a64;
    margin-bottom: 0;
  }
  .invoice-title {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #9ea8b7;
    white-space: nowrap;
  }
}

.alert.alert-warning {
  color: #000 !important;
}

.alert.alert-danger-light {
  background-color: #f8d7da;
  border-color: #f8d7da;
  color: #000;
}

span.anchor{
  cursor:pointer;
  color:#337ab7;
}

span.anchor:hover {
  text-decoration:underline;
}

.order-item-input.order-item-header-item,
.order-item-header .order-item-header-title {
  width: calc(12% - 0.5rem);
  margin-right: 0.5rem;

  &.order-item-small {
    width: calc(9% - 0.5rem);
  }
  &.order-item-big {
    width: calc(23% - 0.5rem);
  }

  @media (max-width: 767px) {
    width: calc(50% - 0.5rem);
    &.order-item-big {
      width: calc(50% - 0.5rem);
    }
    &.order-item-small {
      width: calc(25% - 0.5rem);
    }
    margin-bottom: 0.5rem;
  }
}

.order-item-rm-btn {
  position: absolute;
  top: 9px;
  right: -10px;
  @media (max-width: 767px) {
    top: -6px !important;
  }
}

.nested-fields {
  position: relative;
}

.edit_task {
  .btn {
    @media (max-width: 767px) {
      margin-right: 0.5rem;
    }
  }
}

.badge {
  &-status {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
}

.aside-menu.aside-menu .menu-nav > .menu-item > .menu-link .menu-text {
  transition: color 0.2s ease-out;
  text-align: center;
}
.aside-menu.aside-menu .menu-nav > .menu-item.has-submenu.menu-item-open > .menu-link {
  background-color: #E1F0FF;
  & .menu-text,
  & .menu-arrow {
    color: #3699FF;
  }
  & .menu-icon > i {
    color: #B5B5C3;
  }
}
.aside-menu.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link {
  background-color: #529af6;
  &  .menu-icon i,
  &  .menu-text{
    color: #fff;
  }
}
.aside-menu.aside-menu .menu-nav > .menu-item .menu-submenu .menu-item.menu-item-open > .menu-link {
  background-color: #529af6;
  & .menu-bullet.menu-bullet-line > span {
    background-color: #fff;
  }
  &  .menu-text{
    color: #fff;
  }
}

span.anchor {
  &:hover {
    text-decoration: none !important;
  }
}

.status-dropdown-btn {
  min-width: 130px !important;
}

.status-dropdown-list {
  min-width: 130px !important;
}

.alert.alert-success {
  background-color: #C9F7F5 !important;
  border-color: #C9F7F5 !important;
  color: #086b66 !important;
}

.visit-drivers-select2, .task-drivers-select2, .popup-select {
  & ~ .spinner {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.aside-enabled.aside-minimize {
  .switch-label {
    display: none;
  }
  .switch > label {
    margin-right: 0 !important;
  }
  .custom-quick-search {
    display: none;
    & + .fa-search {
      margin: 11.2px auto !important;
    }
    @media (max-width: 992px) {
      display: block;
    };
  }
  &.aside-minimize-hover {
    .switch-label {
      display: inline-block;
    }
    .switch > label {
      margin-right: 1rem !important;
    }
    .custom-quick-search {
      display: block;
    }
  }
}

.custom-quick-search {
  height: auto !important;
  font-size: 0.95rem !important;

  &:focus {
    color: #000 !important;
  }
  color: #fff !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: inherit !important;
  border-color: #a2a3b7 !important;
}

.tasks.show {
  .subheader {
    .btn.btn-outline-dark {
      border-color: #181c3282;
    }
  }
}

td {
  position: relative !important;
}

.order-items-divider {
  width: 100%;
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
}

.header-mobile {
  .btn.btn-clean:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-clean:focus:not(.btn-text), .btn.btn-clean.focus:not(.btn-text) {
    background-color: transparent;
  }
}

.logo-text {
  font-size: 20px;
  font-weight: 400;
}

.aside-minimize {
  .logo-text {
    display: none;
  }
  &.aside-minimize-hover {
    .logo-text {
      display: block;
    }
  }
}

html {
  @media(min-width: 992px) {
    .header.header-fixed {
      height: 48px;
    }

    .header-fixed.subheader-fixed .header {
      box-shadow: 0 0 20px 0 rgba(0,0,0,0.075) !important;
    }

    .header-fixed.subheader-fixed.subheader-enabled .wrapper {
      padding-top: 102px;
    }

    .header-fixed.subheader-fixed .subheader {
      top: 48px;
    }
  }
}

.daily_route_plan, .tasks.show {
  &.header-fixed.subheader-fixed {
    .subheader {
      @media (min-width: 992px) and (max-width: 1399px) {
        height: auto;
        position: static;
        margin-top: -68px;
        margin-bottom: 10px;
        padding: 10px 0 !important;
      }
    }
  }
}

.daily_route_plan_graphhopper {
  .mod-mb-0 {
    @media (min-width: 1498px) {
      margin-bottom: 0 !important;
    }
  }
}

.pricing {
  &__item {
    position: relative;
  }
  &__ribbon {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    padding: 7px 18px;
    background: #3699FF;
    color: #fff;
    font-size: 11px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  &__tab {
    border: 1px solid #a0cfff;
    color: #000 !important;
    font-size: 11px;
    letter-spacing: 1px;
    &.active {
      background: #cce3ff !important;
    }
  }
}

.bg-light-brown {
  background-color: rgba(191, 90, 8, 0.4);
}

.text-brown {
  color: rgb(191, 90, 8);
}

.bg-dark-brown {
  background-color: rgba(172, 114, 116, 0.4);
}

.text-dark-brown {
  color: rgb(172, 114, 116);
}

.bg-light-orange {
  background-color: rgba(255, 168, 0, 0.4);
}

.text-orange {
  color: rgb(255, 168, 0);
}

@media print {
  .print-hidden {
    display: none;
  }
}

.bg-gradient {
  background: linear-gradient(135deg, #8363a1 0%, #74a8c3 100%);
  min-height: 100vh;
}

.blinking {
  animation: fade 1s infinite alternate;
}

@keyframes fade {
  from { opacity: 0.2; }
}

th.datatable-header-cell span {
  color: #B5B5C3;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;
}
.image-input {
  img.image-input-wrapper {
    object-fit: contain;
    width: 84px;
    height: 84px;
  }
}

span.error {
  color: red;
}

.pricing__item {
  &.active {
    background-color: #ebf5ff;
  }
  transition: 0.5s ease;
  &:hover {
    background-color: #ebf5ff;
  }
  .pricing__button {
    transition: 1s ease !important;
  }
}

.input-group.date {
  min-width: 155px;
  @media (max-width: 1199.98px) {
    min-width: 145px;
  }
  @media (max-width: 991.98px) {
    min-width: 135px;
  }
  @media (max-width: 576px) {
    min-width: 120px;
  }
}

.date-no-min-width .input-group.date, .date-no-min-width.input-group.date {
  min-width: auto;
}

.shopify-banner-background {
  padding: 0;
  position: relative;
  overflow: hidden;

  .card {
    background-color: transparent !important;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: #8950FC !important;
    background-image: url("../../../public/shopify-banner-bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.shopify-app-store {
  svg {
    height: 20px;
    width: 20px;
  }

  .shopify-app {
    min-height: 148px;

    @media (max-width: 576px) {
      min-height: auto;
    }
    @media (min-width: 1630px) and (max-width: 2034px) {
      min-height: 126px;
    }
    @media (min-width: 2035px) {
      min-height: 104px;
    }
  }
}

.active-task-link {
  text-decoration: underline;
  color: black;
  font-weight: 600;
}

.page-content {
  @media(max-width: 576px) {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.card-body {
  @media(max-width: 576px) {
    padding-left: 17px !important;
    padding-right: 17px !important;
  }
}

#calendar {
  tbody tr {
    height: 150px;
  }
  table, thead, tbody, tr, td, th {
    border-color: #d7d7d7;
  }
}

.calendar-event {
  margin: 3px 5px !important;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #d2d2d2;
  box-shadow: 0 0 9px 0 rgba(#fff, 6%);
  transition: 1s ease;

  &:hover {
    background-color: aliceblue;
  }

  .fc-event-title-container {
    padding: 0.55rem 0.55rem 0.55rem 2rem;
    white-space: normal;

    &::before {
      display: block;
      content: " ";
      position: absolute;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      top: 50%;
      transform: translateY(-50%);
      left: 0.75rem;
      background: #EBEDF3;
    }
  }

  &.unassigned .fc-event-title-container::before {
    background: $unassigned-status-color;
  }
  &.assigned .fc-event-title-container::before {
    background: $assigned-status-color;
  }
  &.active .fc-event-title-container::before {
    background: $active-status-color;
  }
  &.failed .fc-event-title-container::before {
    background: $failed-status-color;
  }
  &.completed .fc-event-title-container::before {
    background: $completed-status-color;
  }
  &.roundtrip_delivered .fc-event-title-container::before {
    background: $roundtrip-delivered-status-color;
  }
  &.canceled .fc-event-title-container::before {
    background: $canceled-status-color;
  }
  &.in_inventory .fc-event-title-container::before {
    background: $in-inventory-status-color;
  }
  &.out_inventory .fc-event-title-container::before {
    background: $out-inventory-status-color;
  }

  @media (max-width: 800px) {
    margin: 1px 3px !important;

    .fc-event-title-container {
      padding: 0.4rem;

      &::before {
        background: transparent;
      }
    }
  }

  .fc-event-title {
    color: #3F4254;
  }
}

.fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end.wpfc-more {
  background: #ffffff !important;
  border-color: #000 !important;
}

.applications {
  .flex-basis-20-50 {
    flex-basis: 20%;
  }

  .flex-basis-80-50 {
    flex-basis: 80%;
  }

  @media (max-width: 992px) {
    .flex-basis-20-50, .flex-basis-80-50 {
      flex-basis: 50%;
    }
  }
}

.couriers {
  .flex-basis-20-50 {
    flex-basis: 20%;
  }

  @media (max-width: 992px) {
    .flex-basis-20-50 {
      flex-basis: 50%;
    }
  }
}

.react-import-review {
  .ant-table-content {
    padding-bottom: 1rem;
  }

  .ant-table-cell {
    padding: 0 16px !important;
    min-width: 182px !important;
    height: 40px;
    input {
      width: 150px;
    }
  }

  .ant-btn {
    &.antd-active-btn {
      color: #40a9ff;
      background: #fff;
      border-color: #40a9ff;
    }
  }
}

.ant-select.rounded {
  .ant-select-selector {
    border-radius: 0.42rem !important;
  }
}

.table tr {
  transition:  0.5s ease;
}

.floating-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  bottom: 75px;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background: white;
  border-radius: 5px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);

  white-space: nowrap;

  @media(max-width: 992px) {
    min-width: 70%;
    flex-wrap: wrap;
  }

  @media(max-width: 768px) {
    min-width: 85%;
  }

  &-items {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  &-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #f2f3f5;

    @media(max-width: 576px) {
      justify-content: space-around;
      flex-wrap: wrap;
    }

    &-items {
      display: flex;
      align-items: center;

      @media(max-width: 576px) {
        flex-wrap: wrap;
        justify-content: space-around;
      }
    }
  }
}

.simple-shadow, .card.card-custom.simple-shadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
}

@media(min-width: 992px) {
  .simple-shadow-lg, .card.card-custom.simple-shadow-lg {
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
  }
}


.shadow-hover {
  transition: 0.5s ease;
  &:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
  }
}

.simple-scroll,
.simple-scroll * {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0,0,0,0.1);
  }

  &::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background: rgba(0,0,0,0.2);
  }

  &::-webkit-scrollbar-thumb:hover{
    background: rgba(0,0,0,0.4);
  }

  &::-webkit-scrollbar-thumb:active{
    background: rgba(0,0,0,.9);
  }
}

.simple-scroll.simple-scroll-thick,
.simple-scroll.simple-scroll-thick * {
  &::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }
}

.no-scroll::-webkit-scrollbar {
  display: none;
}

#operator-store-visits-table_wrapper {
  .dataTables_scroll {
    margin-top: 0.5em !important;
  }

  .dataTables_scrollBody {
    min-height: 55vh;
  }

  .dataTables_info {
    padding-top: 0.4em;
  }
}

#daily-route-plan-table_wrapper {
  .dataTables_scroll {
    margin-top: 0 !important;
  }
}

.r-quick-search {
  display: flex;
  .quick-search-wrapper {
    display: flex !important;
  }
}

.quick-search-dropdown {
  min-width: 406px !important;
  max-height: 600px;
  overflow-y: scroll;
  .task-row:hover {
    background-color: aliceblue;
  }
  .input-group {
    background-color: transparent;
    .input-group-prepend {
      .input-group-text {
        padding-left: 0;
      }
    }
    .input-group-append {
      .input-group-text {
        padding-right: 0;
      }
    }
  }
  .input-group-prepend {
    background-color: transparent;
    .input-group-text {
      background-color: transparent;
      border: 0;
    }
  }
  .input-group-append {
    padding: 0;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    .input-group-text {
      background-color: transparent;
      border: 0;
    }
  }
  .form-control {
    border: 0;
    background-color: transparent;
    outline: none !important;
    box-shadow: none;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    padding: 0;
    border-radius: 0;
  }
  .quick-search-close {
    cursor: pointer;
  }
  .input-group:before {
    right: 0;
  }
}

.plugins-image-wrapper {
  width: 75px;
  height: 75px;
  background: #fff;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
}

.leaflet-draw-toolbar {
  .leaflet-draw-draw-polygon {
    background: #FFF url('../../../public/polygon-icon.png') no-repeat 50% 50% !important;
    background-size: 65% 65% !important;
  }

  .leaflet-draw-edit-edit {
    background-image: none !important;

    &:before {
      font-family: "Font Awesome 6 Pro";
      content: "\f044";
      color: black;
      font-size: 17px;
      font-weight: 300;
    }
  }
}

#colvis {
  &.colvis-small {
    zoom: 0.9;
  }

  &.colvis-left {
    .dt-button-collection .dropdown-menu {
      left: 0 !important;
      right: auto !important;
    }
  }

  position: relative;
  .buttons-colvis {
    &::after {
      margin: 0;
      position: relative !important;
      top: 0 !important;
      right: 0 !important;
    }
  }

  .dt-button-collection {
    .dropdown-menu {
      display: block;
      font-size: 0.9em;
      left: auto !important;
      right: 0 !important;

      a {
        margin: 4px auto;
        border-radius: 5px;
        padding: 3px 10px;

        &.active {
          background: #62a7ef;
        }
      }
    }
  }
}

.daterangepicker-init {
  min-width: 190px;
  background: #fff;
  cursor: pointer;
  border: 1px solid #E4E6EF;
  border-radius: 5px
}

.daterangepicker {
  z-index: 101 !important;

  &.show-calendar.show-ranges .ranges {
    height: auto !important;
  }

  .ranges {
    ul {
      display: flex;
      flex-direction: column;
      li {
        display: flex;
        justify-content: flex-start;
      }
    }
  }
}

.apexcharts-pie-label {
  font-size: 16px !important;
}

.apexcharts-tooltip {
  color: #000 !important;
}

#status-summary-chart {
  width: 85%;

  .apexcharts-legend {
    justify-content: center !important;
    top: 0 !important;
    color: #000 !important;
  }

  .apexcharts-pie-series {
    cursor: pointer;
  }

  .apexcharts-legend-marker {
    margin-right: 10px;
    margin-left: 10px;
  }

  @media (max-width: 1700px) {
    width: 90%;
  }

  @media (max-width: 1500px) {
    width: 100%;
  }

  @media (max-width: 992px) {
    .apexcharts-legend {
      justify-content: normal !important;
      top: auto !important;
    }

    .apexcharts-legend-marker {
      margin-right: 7px;
      margin-left: 7px;
    }
  }

  @media (max-width: 768px) {
    .apexcharts-legend {
      justify-content: center !important;
      top: 0 !important;
    }
  }

  @media (max-width: 576px) {
    .apexcharts-legend {
      justify-content: normal !important;
      top: auto !important;
    }
  }
}

.daily_progress {
  #status-summary-chart {
    width: 100%;

    .apexcharts-legend {
      justify-content: normal !important;
      top: auto !important;
    }

    .apexcharts-legend-marker {
      margin-right: 7px;
      margin-left: 7px;
    }

    @media (max-width: 768px) {
      .apexcharts-legend {
        justify-content: center !important;
        top: 0 !important;
      }
    }

    @media (max-width: 576px) {
      .apexcharts-legend {
        justify-content: normal !important;
        top: auto !important;
      }
    }
  }
}


.aside-menu-wrapper .aside-menu .menu-nav > .menu-item > .menu-link .menu-icon {
  flex: 0 0 30px;
  justify-content: center;
  padding: 0 8px;
}

.aside-menu-wrapper .aside-menu .menu-nav > .menu-item > .menu-link {
  padding: 9px 13px;
}

.aside > .brand {
  box-shadow: 0 4px 6px 0 rgba(#000, 0.03);
  z-index: 1;
}

.menu-item.menu-item-submenu {
  .menu-icon > i {
    font-size: 1.55rem;
  }

  &:hover .menu-icon > i {
    color: #3699FF;
  }

  & > a.menu-link.menu-toggle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
    padding: 7px 0 !important;
    margin: 0 8px;
    border-radius: 8px;
  }
}

@media (min-width: 992px) {
  .aside-minimize:not(.aside-minimize-hover) .aside-menu .menu-nav > .menu-item {
    height: 68.5px
  }

  .aside-minimize:not(.aside-minimize-hover) .aside-menu .menu-nav .menu-link.menu-toggle.menu-dropdown-link {
    &:after {
      display: none;
    }
  }
}

.menu-nav > .menu-item.menu-item-submenu {
  position: static !important;
  margin-bottom: 2px !important;
  margin-top: 2px !important;
}

.menu-link.menu-toggle.menu-dropdown-link {
  &:after {
    content: "\f105";
    font-family: "Font Awesome 6 Pro";
    color: #B5B5C3;
    font-weight: 300;
    position: absolute;
    top: 40%;
    right: 20px;
    transform: translateY(-50%);
    transition: 0.5s ease;
  }

  &:hover {
    &:after {
      color: #3699FF;
    }
  }
}

.menu-item.menu-item-submenu.has-submenu {
  .menu-custom-submenu {
    background: white;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    display: block;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    width: auto;
    left: calc(100% - 6px);
    transition: 0.3s ease;
  }

  &.custom-submenu-open {
    .menu-custom-submenu {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &.menu-item-open {
    .menu-icon > i,
    .menu-link.menu-toggle.menu-dropdown-link::after {
      color: #3699FF !important;
    }
  }
}

.custom-submenu-list {
  list-style: none;
  padding: 5px 0;
  margin: 0;
  color: #000;
  white-space: nowrap;

  .custom-submenu-item {
    a {
      display: inline-block;
      width: 100%;
      padding: 10px 20px;
      cursor: pointer;
      transition: 0.5s ease;
      color: #000;

      &:hover {
        background: #F3F6F9;
        color: #3699FF;
      }
    }

    &.menu-item-open {
      a {
        background: #529af6;
        color: white;

        &:hover {
          background: #529af6;
          color: white;
        }
      }
    }

  }
}

.rounded-5 {
  border-radius: 5px;
}

.rounded-16 {
  border-radius: 16px;
}

.subheader .nav.nav-tabs {
  font-size: 15px;
  & .nav-link.active {
    border-bottom: 1px solid #4e96d1;
  }
}
.img-circle {
  border-radius: 50% !important;
}
.img-rounded {
  border-radius: 6px !important;
}
.dropdown-menu .navi .navi-ico{
  width: 30px;
  margin-right: 8px;
  text-align: center;
}

.dropdown.show > .arrow{
  position: absolute;
  bottom: -1px;
  right: 50px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid #fff;
  z-index: 97;
  transition-delay: 0.3ms;
  animation: animation-dropdown-menu-fade-in 0.3s ease 1, animation-dropdown-menu-move-up 0.3s ease-out 1;
}
.dropdown.show > .arrow-shadow{
  position: absolute;
  bottom: 0;
  right: 50px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid rgba(0, 0, 0, 0.5);
  filter: blur(5px);
  z-index: 0;
  animation: animation-dropdown-menu-fade-in 0.3s ease 1, animation-dropdown-menu-move-up 0.3s ease-out 1;
}

.actions-group {
  border-radius: 10px;
  background: #f5f6fa;
  display: flex;
  flex-wrap: nowrap;
}
.shadow-sm-10{
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5) !important;
}
.actions-link {
  color: #000;
  padding: 10px 10px 7px;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.actions-link:hover,
.actions-link:focus,
.actions-link.active {
  color: #3699FF;
  box-shadow: inset 0 -2px 0 0 #3699FF;
}

body.login .content .input-icon{
  // TODO Look at the login-3.css may be it not needed file
  border-left-width: 0 !important;
}

body.login .form-control::placeholder {
  color: #666;
  opacity: 1;
}

.swal2-icon {
  justify-self: center;
}

.image-wrapper {
  object-fit: cover;
  border-radius: 0.42rem;
  border: 3px solid #ffffff;
}

.bg-primary-200 {
  background-color: #def3fe;
}

.offcanvas-resize-toggle, .resizable-map-toggle {
  position: absolute;
  cursor: col-resize;

  &-icon {
    width: 35px;
    height: 55px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    left: 0;
    top: 50%;
    z-index: 5;
    transform: translateX(-100%);
  }

  &-border {
    left: 0;
    height: 100%;
    width: 10px;
    z-index: 4;
    transform: translateX(-50%);
    background: transparent;
  }
}

.offcanvas.offcanvas-right.offcanvas-custom {
  width: 400px;
  right: 0;
  box-sizing: border-box;
  max-width: 75vw;
  top: 102px;
  z-index: 3;
  transition: 0.5s ease;
}

.offcanvas.offcanvas-custom-right {
  right: 0;
  left: auto;
  transform: translateX(100%);
  transition: 0.5s ease !important;

  &.offcanvas-on {
    left: auto;
    transform: translateX(0);
  }
}

.applied-filters-container {
  position: relative;
  transition: 1s ease;

  &::before {
    position: absolute;
    content: "\f0b0";
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    left: 0;
    top: 0;
    color: #3699FF;
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  .filter-applied {
    display: flex;
    align-items: center;
    transition: 1s ease;
  }

  :nth-child(1 of .filter-applied:not(.d-none)) {
    margin-left: 25px;
  }
}

.filter-badge {
  display: flex;
  align-items: center;
  color: #3699FF;
  border: 1px solid #3699FF;
  border-radius: 0.42rem;
  padding: 0 0.75rem;
  transition: 0.15s ease;

  &.filter-badge-btn {
    cursor: pointer;

    color: #fff;
    background-color: #3699FF;
  }

  &.filter-badge-hover-btn {
    cursor: pointer;

    &:hover {
      background-color: #3699FF;
      color: #fff;
    }
  }
}

.pagination-left .pagination {
  justify-content: flex-start !important;
}

@media (max-width: 1400px) {
  .dataTables_wrapper .dataTables_paginate {
    margin-bottom: 12px !important;
  }
}

.select2-small {
  .select2-selection.select2-selection--single {
    min-height: 30px;

    .select2-selection__rendered {
      padding: 0.1rem 2rem 0.1rem 1rem;
    }
  }
}

.visit-driver-select2-mobile {
  @media (max-width: 576px) {
    .select2 {
      width: 100% !important;
      max-width: 150px !important;
      min-width: 100px !important;
    }
  }

  @media (max-width: 400px) {
    .select2 {
      max-width: 125px !important;
    }
  }

  @media (max-width: 350px) {
    .select2 {
      max-width: 100px !important;
    }
  }
}

$registration-inputs-color: #fafafa;
.registration-form {
  font-size: 15px;
  .ant-form-item-label > label {
    height: 44px;
    margin: 0;

    &::before {
      position: absolute;
      right: -5px;

      @media (max-width: 576px) {
        right: -15px;
      }
    }

    @media (max-width: 576px) {
      height: 30px;
    }
  }

  .ant-input, .google-autocomplete-input {
    height: 44px;
    background: $registration-inputs-color;
    border-radius: 10px;
    padding-left: 26px;
    outline: none;
    transition: 0.2s ease;
    font-size: 14px;

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 50px $registration-inputs-color inset;
    }
  }

  .ant-input::placeholder {
    color: #9e9e9e;
  }

  .ant-input-password {
    border-radius: 10px;
    background: $registration-inputs-color;
    height: 44px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 26px;

    .ant-input {
      height: 42px;

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 50px $registration-inputs-color inset;
      }
    }
  }

  .google-autocomplete-wrapper {
    position: relative;

    .google-autocomplete-input {
      padding-right: 45px;
    }

    &::before {
      content: "\f002";
      font-family: "Font Awesome 6 Pro";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      color: #888;
    }
  }

  .ant-select:not(.ant-select-customize-input) {
    border-radius: 10px;
    background: $registration-inputs-color;
    height: 44px;

    .ant-select-selector {
      border-radius: 10px;
      padding: 6px 24px;
      background: $registration-inputs-color;
    }

    .ant-select-selection-placeholder {
      color: #9e9e9e;
    }

    .ant-select-arrow {
      right: 24px;
    }

    .ant-select-selection-search-input {
      height: 42px;
      padding-left: 12px;
    }
  }

  .registration-submit {
    height: 44px;
    font-size: 18px;
  }

  .ant-form-item-control {
    justify-content: center;
  }
}

.new-task-form {
  .ant-modal-body {
    border-radius: 8px;
  }
  .ant-checkbox-inner {
    width: 20px;
    height: 20px;

    &::after {
      width: 8px;
      height: 12px;
    }
  }
  .ant-checkbox {
    font-size: 20px;
  }
  @media (max-width: 575px) {
    .ant-form-item .ant-form-item-label {
      padding: 0;
    }
    .mobile-checkbox-row {
      .ant-form-item-control {
        max-width: 30px !important;
      }
      .ant-form-item-row {
        flex-flow: row-reverse;
        justify-content: start;
      }
    }
  }
}

.dataTables_processing {
  border: none !important;
  position: absolute !important;
  top: 27px !important;
}

.import-row {
  .card-custom {
    min-height: 225px;
  }
}

@media screen {
  #printSection {
    display: none;
  }
}

@media print {
  body.part-print {
    * {
      visibility:hidden;
    }

    #printSection, #printSection * {
      visibility: visible;
      font-size: 25px;
    }
    #printSection {
      position:absolute;
      left: 0;
      right: 0;
      top: 0;

      table.dataTable {
        th {
          color: #000 !important;
          &::before, &::after {
            display: none;
          }
        }
      }
    }
  }
}

.data-preloader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 2000;
  .fa-spinner {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
  }
}

.dataTables_wrapper .dataTables_processing {
  background:none;
}

#datetimerangepicker {
  min-width: 350px;
}

.statuses-steps {
  border: 0 solid #ccc;
  background: #f5f5f5;
  border-radius: 5px;
  overflow: hidden;
  padding: 0;

  li {
    display: block;
    padding: 15px 20px 15px 40px;
    color: #bbb;
    background: #ddd;
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 0;
      height: 100%;
      border-top: 24px solid transparent;
      border-bottom: 24px solid transparent;
      border-left: 20px solid white;
      position: absolute;
      top: 0;
      margin-left: 1px;
      left: 100%;
      z-index: 1;
    }

    &::after {
      content: "";
      display: block;
      width: 0;
      height: 100%;
      border-top: 24px solid transparent;
      border-bottom: 24px solid transparent;
      border-left: 20px solid #ddd;
      position: absolute;
      top: 0;
      left: 100%;
      z-index: 2;
    }

    &.done {
      background-color: #5cb85c;
      color: #fff;

      &::after {
        border-left-color: #5cb85c;
      }
    }

    &.active {
      background-color: #5bc0de;
      color: #fff;
      &::after {
        border-left-color: #5bc0de;
      }
    }
  }

  @media(max-width: 576px) {
    font-size: 11px;

    li {
      padding: 8px 12px 8px 25px;

      &::before {
        border-top: 16.25px solid transparent;
        border-bottom: 16.25px solid transparent;
        border-left: 12px solid white;
        z-index: 1;
      }

      &::after {
        border-top: 16.25px solid transparent;
        border-bottom: 16.25px solid transparent;
        border-left: 12px solid #ddd;
      }

    }
  }
}

.vip-group-star {
  color: gold;
}

@media (max-width: 576px) {
  .date-actions {
    flex-wrap: wrap;
    .actions-group {
      margin-bottom: 0.25rem;
      justify-content: space-evenly;
      flex-grow: 1;
    }
  }
}

.rating {
  --starsize: 3rem;
  --stars: 5;
  --value: 1;
  --fill: gold;
  --fillbg: rgba(100, 100, 100, 0.15);
  position: relative;
  display: flex;
  width: calc(var(--starsize) * var(--stars));
  height: var(--starsize);

  &.rating-lg {
    --starsize: 4rem;
  }

  &.rating-sm {
    --starsize: 2rem;
  }

  &.rating-xs {
    --starsize: 1.5rem;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: calc((var(--stars) - var(--value)) * var(--starsize));
    bottom: 0;
    top: 0;
    background: var(--fill);
    mask: repeat left center / var(--starsize) url('../../../public/star.svg');
    -webkit-mask: repeat left center / var(--starsize) url('../../../public/star.svg');
    z-index: 2;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: var(--fillbg);
    mask: repeat left center / var(--starsize) url('../../../public/star.svg');
    -webkit-mask: repeat left center / var(--starsize) url('../../../public/star.svg');
  }

  .rating-radio {
    width: var(--starsize);
    height: var(--starsize);
    opacity: 0;
    z-index: 3;
  }
}

.calculating {
  opacity: 0;
}

.filters {
  .select2-container--default .select2-selection--multiple {
    line-height: 38px !important;
  }
}

.purchase-filters {
  .select2-selection--multiple {
    line-height: 38px !important;
  }
  input {
    min-height: 45px !important;
  }
}

.pick-status {
  min-width: 70px;

  &.status-dropdown-btn {
    min-width: 100px !important;
  }

  &-new {
    background-color: red !important;
    color: #000 !important;
  }

  &-pending {
    background-color: yellow !important;
    color: #000 !important;
  }

  &-picked {
    background-color: green !important;
    color: #fff !important;
  }

  &-partially_picked {
    background-color: orange !important;
    color: #fff !important;
  }

  &-new.status-dropdown-btn i,
  &-pending.status-dropdown-btn i {
    color: #000 !important;
  }

  &-picked.status-dropdown-btn i {
    color: #fff !important;
  }

  &-partially_picked.status-dropdown-btn i {
    color: #fff !important;
  }
}

.comments-count {
  position: relative;
  font-size: 18px;

  span {
    position: absolute;
    top: 1px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 11px;
    font-weight: bold;
  }
}

.operator.store_visits .tooltip-inner {
  max-width: 400px !important;
}

.filter-wrapper {
  .dropdown-menu {
    min-width: 150px;
    padding: 8px;
    z-index: 100;

    & > * {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.daily-progress-grid {
  display: grid;
  grid-template: 1fr 0.5fr 1fr / 350px repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  &-drivers  { grid-area: 1 / 1 / 4 / 2; }
  &-map      { grid-area: 1 / 2 / 3 / 5; }
  &-progress { grid-area: 3 / 2 / 4 / 3; }
  &-statuses { grid-area: 3 / 3 / 4 / 4; }
  &-chart    { grid-area: 3 / 4 / 4 / 5; }

  &-map { min-height: 400px; }
  &-statuses {
    display: grid;
    grid-template: repeat(4, 1fr) / 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;

    @media (max-width: 1400px) {
      grid-template: repeat(2, 1fr) / repeat(2, 1fr);
    }

    @media (max-width: 576px) {
      grid-template: repeat(4, 1fr) / 1fr;
    }
  }

  @media (max-width: 1400px) {
    grid-template: 2fr 1fr auto / repeat(6, 1fr);

    &-drivers  { grid-area: 1 / 1 / 2 / 3; }
    &-map      { grid-area: 1 / 3 / 2 / 7; }
    &-progress { grid-area: 2 / 1 / 3 / 4; }
    &-statuses { grid-area: 3 / 1 / 4 / 7; }
    &-chart    { grid-area: 2 / 4 / 3 / 7; }
  }

  @media (max-width: 768px) {
    grid-template: 1fr repeat(4, auto) / 1fr;

    &-drivers  { grid-area: 2 / 1 / 3 / 2; }
    &-map      { grid-area: 1 / 1 / 2 / 2; }
    &-progress { grid-area: 3 / 1 / 4 / 2; }
    &-statuses { grid-area: 5 / 1 / 6 / 2; }
    &-chart    { grid-area: 4 / 1 / 5 / 2; }
  }
}

.input-dropzone {
  position: relative;

  .dropzone-content {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .dropzone-text {
      color: #3F4254;
      margin: 0 0 5px 0;
      padding: 0;
      font-weight: 500;
      font-size: 1.2rem;
      transition: 0.1s ease;
    }

    .dropzone-file {
      display: flex;
      align-items: center;

      &-name {
        font-size: 15px;
      }
    }
  }

  input[type='file'] {
    cursor: pointer;
    position: absolute;
    opacity: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.y-rotate {
  transform: scale(1, -1);
}

.purchase-filters {
  .select2-selection--multiple {
    line-height: 38px !important;
  }
  input {
    min-height: 45px !important;
  }
}

.colorpicker-init {
  color: transparent !important;

  &:focus-visible {
    text-shadow: none !important;
  }
}

.badge {
  &.badge-route-planned {
    background-color: $unassigned-status-color;
  }
  &.badge-route-optimized {
    background-color: $assigned-status-color;
  }
  &.badge-route-active {
    background-color: $active-status-color;
  }
  &.badge-route-done {
    background-color: $completed-status-color;
    color: white;
  }
  &.badge-route-optimization-failed {
    background-color: $failed-status-color;
    color: white;
  }
}

.switch input ~ span:after {
  transition: 0.5s ease !important;
}

.switch.switch-grey:not(.switch-outline) input:checked ~ span:before {
  background-color: #eee;
}
.switch.switch-grey:not(.switch-outline) input:empty ~ span:before {
  background-color: #eee;
}

.switch.switch-border > span:before {
  border: 1px solid #dcd3d3;
}

.route-row {
  transition: 0.5s ease;
}

#fake-table-tbody {
  tr:first-child {
    height: 30px;
  }
}

@media (max-width: 576px) {
  .public_pages {
    #locate_show_task_map,
    #edit_geolocation_map,
    #live_track_map {
      margin-left: -15px;
      margin-right: -15px;
      border-radius: 0;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        box-shadow: inset 0 25px 25px -20px rgba(#000 ,0.4);
        pointer-events: none;
      }
    }
  }
}

.table-responsive.place-top {
  margin-top: -125px;
  padding-top: 125px;
  -webkit-overflow-scrolling: auto !important;
}

.table-responsive.dt-dropdowns-place {
  margin-top: -75px;
  padding-top: 75px;
  -webkit-overflow-scrolling: auto !important;

  .dataTables_wrapper {
    min-height: 300px;
  }
}

.locate-tabs {
  .nav-link {
    border-width: 2px !important;
    cursor: pointer;
  }

  @media (max-width: 576px) {
    margin-right: -15px;
    margin-left: -15px;
    .nav-link {
      width: 100%;
      justify-content: center;
    }
  }
}

.statuses-timeline {
  .timeline-item:last-child {
    padding-bottom: 0 !important;
  }
}

label.checkbox .filter-option-count {
  display: inline;
}

.table tr:not([style*=background-color]):hover {
  background-color: #f9f9f9;
}

.filter-paid-w, .filter-in-inventory-w, .filter-from-exhibit-w {
  .select2-selection {
    min-height: 45px;
  }
}

.excel-config {
  &.config-wrapper {
    display: flex;
    width: 600px;
    background-color: #EEF0F8;
    .list-group {
      .list-group-item {
        cursor: pointer;
      }
      min-width: 300px;
    }
  }
}

.hover-copy {
  cursor: pointer;
  position: relative;
  display: inline-block;

  &::after {
    font-family: 'Font Awesome 6 Pro';
    font-weight: 300;
    transition: 0.5s;
    opacity: 0;
    margin-left: 4px;
    margin-right: 4px;
    color: #3699FF;
    font-size: 1.05em;
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-60%);
    pointer-events: none;
  }

  &:hover::after {
    opacity: 1;
  }
}

.hover-copy {
  &::after {
    content: "\f24d";
  }

  &.hover-copy-success {
    &::after {
      content: "\f00c";
      color: green;
    }
  }
}

.grid-4-labels-per-page,
.grid-8-labels-per-page {
  display: grid;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  border: 2px solid #000;
  border-radius: 10px;
  padding: 5px;
}

.grid-4-labels-per-page {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.grid-8-labels-per-page {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.label-start-position {
  transition: 0.5s ease;
  cursor: pointer;
  border: 2px dashed #E4E6EF;
  border-radius: 7px;
  position: relative;

  &.active {
    border-color: #3699FF;

    &::after {
      content: '1';
      font-size: 25px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      font-family: Arial, Helvetica, sans-serif;
      pointer-events: none;
    }
  }
}

.filter-clear {
  z-index: 1;
}

.custom-popup {
  z-index: 1;
  position: absolute;
  left: 50%;
  bottom: calc(100% + 15px);
  transform: translateX(-50%);
  background-color: white;
  border-radius: 0.42rem;
  padding: 15px;
  box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(#000, 0.08);

  &::after {
    content: '';
    left: 50%;
    top: 100%;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: white transparent transparent transparent;
    transform: translateX(-50%);
  }
}

.search-input-no-clear {
  &::-ms-reveal { display: none; width : 0; height: 0; }
  &::-ms-clear { display: none; width : 0; height: 0; }

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration { display: none; }
}

.password-eye {
  &-container {
    position: relative;
  }

  &-input.form-control {
    padding-right: 40px;
  }

  &-icon {
    margin: 0;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    &.fa-eye:not(.password-open) {
      padding-right: 1px;
    }

    &.fa-eye.password-open:before {
      content: "\f070";
    }
  }
}

.mobile-preview {
  width: 450px;
  height: 810px;

  zoom: 0.725;
  padding: 60px 50px 22px 48px;

  background: url('../../../public/phone_frame.svg') no-repeat center;
  background-size: contain;

  font-size: 12px;

  &-content {
    border-left: 2px solid #000;
    border-radius: 0 0 35px 35px;
  }

  position: relative;

  &-loader {
    z-index: 2;
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    content: '';
    transform: translateY(-50%) translateX(-50%);
  }

  iframe {
    border-bottom-left-radius: 55px;
    border-bottom-right-radius: 55px;
    width: 150%;
    height: 150%;
    transform: scale(0.666);
    transform-origin: 0 0;
  }
}

.mobile-preview-tab-link {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 5px 10px 5px 32px;
  border-radius: 5px;
  color: #000;
  text-decoration: none;
  transition: 0.5s ease;

  &:before {
    position: absolute;
    content: '';
    left: 6px;
    top: 50%;
    transform: translateY(-50%);
    border: 1px solid grey;
    border-radius: 50%;
    width: 16px;
    height: 16px;
  }

  &:after {
    opacity: 0;
    position: absolute;
    content: '';
    left: 14px;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #3699FF;
  }

  &.active {
    &:before {
      border-color: #3699FF;
    }

    &:after {
      opacity: 1;
    }
  }

  &:hover {
    &:before {
      border-color: #3699FF;
    }
  }
}

.monthly-chart {
  .apexcharts-tooltip-title {
    margin-bottom: 0;
  }
}

.dataTables_wrapper .dataTable td, .dataTables_wrapper .dataTable th {
  padding: 0.3rem
}

@media (min-width: 992px) {
  .content {
    padding: 15px 0;
  }

  .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    padding: 0 15px;
  }
}

input.hide-arrows {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }
}

.selected-chat {
  background: #ededed;
  color: white;
  border-radius: 8px;
}

.company .company_message, .organization .organization_message {
  align-items: flex-end !important;
}

.company .organization_message, .organization .company_message {
  align-items: flex-start !important;
}

.company .bgc-company_message, .organization .bgc-organization_message {
  background-color: #E1F0FF !important;
}

.company .bgc-organization_message, .organization .bgc-company_message {
  background-color: #C9F7F5 !important;
}

#tickets_list table td { border: none !important; }

#chat_content #chat_messages {
  overflow: scroll;
}

.msg_info span {
  text-align: center;
  line-height: 0px;
}

.empty_status {
  justify-content: center;
  text-align: center;
  height: inherit;
}

@media (max-width: 1220px) {
  .top-button {
    margin-bottom: 0.5rem;
  }
}

#unread-news-badge {
  position: absolute;
  font-size: 10px;
  right: -5px;
  top: -4px;
  width: 14px;
  height: 14px;
  line-height: 15px;
  color: white;
  background-color: red;
  border-radius: 50%;
  text-align: center;
  font-family: "Arial", sans-serif;
  font-weight: 600;
}

.sticky-setting-menu {
  position: sticky;
  top: 130px;
}

.navi-side-custom li.navi-item a.active,
.navi-side-custom li.navi-item a.active:hover {
  background-color: #529af6 !important;
  color: #ffffff !important;
  border-radius: 5px;
}

.dynamic_variable_table td {
  padding: 2px !important;
}

@media(min-width: 992px) {
  .sticky-setting-menu-lg {
    position: sticky;
    top: 130px;
  }
}

@each $id, $zoom in (0_5: 0.5, 0_6: 0.6, 0_7: 0.7, 0_8: 0.8, 0_9: 0.9, 1_0: 1, 1_1: 1.1, 1_2: 1.2, 1_3: 1.3, 1_4: 1.4, 1_5: 1.5) {
  .zoom-#{$id} {
    zoom: $zoom;
  }
}

.card-sticky-on .card-header.sticky-background-before {
  &::before {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: 100%;
    height: 17px;
    background-color: #EEF0F8;
    box-shadow: rgba(100, 100, 111, 0.05) 0 -65px 20px -40px inset;
  }
}

.navi-active.navi-active-primary {
  .navi-link.active {
    background-color: #529af6 !important;

    .navi-text, .navi-icon i {
      color: #fff !important;
    }
  }
}

.sms-general-timepicker {
  min-width: 105px;
}

.link_with_icon {
  white-space: nowrap;

  .fa-light {
    font-size: unset;
  }
}

.chat.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.chat.image-input-wrapper [data-action=download] {
  display: none;
}

.chat.image-input-wrapper:hover [data-action=download] {
  display: block;
}

.custom-search-input {
  &-wrapper {
    position: relative;
    input {
      padding: 0 25px 0 30px;
      color: #000;
      background: #fff;
      border-radius: 7px;
      border: 1px solid #ddd;

      &.header-search {
        width: 380px;
        max-height: 35px;
        line-height: 35px;
      }

      &:focus {
        outline: 0;
        border-color: #ddd;
      }

      &::placeholder {
        line-height: 35px;
      }
    }

    &::before {
      content: "\f002";
      font-family: "Font Awesome 6 Pro";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
      color: #888;
    }
  }

  &-right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    color: #888;
    line-height: 10px;
  }
}

.sms-amount-number {
  position: absolute;
  left: 50%;
  top: 48%;
  transform: translateX(-50%) translateY(-50%);
  font-family: Helvetica, sans-serif;
  font-size: 14px
}

.filter-visibility {
  cursor: pointer;
  border-radius: 5px;
  padding: 2px 10px;

  &-active {
    background-color: #529af6;
    color: #ffffff;
  }
}
.driver-status-icon {
  position: absolute;
  right: 2px;
  bottom: 1px;
  line-height: 13px;

  i {
    font-size: 13px
  }
}

.task-row-active {
  background-color: #ddeefd;
}

.offcanvas-close-container {
  position: absolute;
  right: 5px;
  top: 10px;
}

.bg-yellow {
  background-color: yellow;
}


.cookies-banner, .update-terms-banner, .payme-banner {
  position: fixed;
  z-index: 999;
  right: 0;
  bottom: 0;
  left: 0;

  border-top: 1px solid #E4E4E4;
  background: #F2F2F2;
}

.update-terms-banner {
  z-index: 1000;
  background-color: #ff1010;
}

.hide-on-toggle[aria-expanded="true"] {
  display: none;
}

.disable-styled {
  pointer-events: none;
  cursor: not-allowed;
  background-color: #f3f6f9!important;
}

/* Background color for DataTable Next and Previous buttons */
.orders .dataTables_wrapper .dataTables_paginate .pagination .page-item.next .page-link,
.orders .dataTables_wrapper .dataTables_paginate .pagination .page-item.previous .page-link {
  background-color: #007bff !important;
  border-color: #007bff !important;
  color: white !important;
}

.nav .nav-link.nav-link-black {
  color: #000;
}

.badge.badge-light-primary {
  color: #3699FF;
  background-color: #E1F0FF;
  border-color: transparent;
}

.badge.badge-light-danger {
  color: #d9534f;
  background-color: #F3CBCA;
  border-color: transparent;
}

.badge.badge-light-secondary {
  color: #9FA6B2;
  background-color: #E1F0FF;
  border-color: transparent;
}

.badge.badge-light-success {
  color: #14A44D;
  background-color: #D0ECDB;
  border-color: transparent;
}

.badge.badge-light-notice {
  color: #E4A11B;
  background-color: #FCF6EA;
  border-color: transparent;
}

/* StoreVisits data-table ui changes */
#operator-store-visits-table.dataTable > thead .sorting:before,
#operator-store-visits-table.dataTable > thead .sorting_asc:before,
#operator-store-visits-table.dataTable > thead .sorting_asc_disabled:before,
#operator-store-visits-table.dataTable > thead .sorting_desc:before,
#operator-store-visits-table.dataTable > thead .sorting_desc_disabled:before {
  content: "";
}

#operator-store-visits-table.dataTable > thead .sorting:after,
#operator-store-visits-table.dataTable > thead .sorting_asc:after,
#operator-store-visits-table.dataTable > thead .sorting_asc_disabled:after,
#operator-store-visits-table.dataTable > thead .sorting_desc:after,
#operator-store-visits-table.dataTable > thead .sorting_desc_disabled:after {
  content: "";
}

#operator-store-visits-table.dataTable > thead .sorting:hover:before,
#operator-store-visits-table.dataTable > thead .sorting_asc:hover:before,
#operator-store-visits-table.dataTable > thead .sorting_asc_disabled:hover:before,
#operator-store-visits-table.dataTable > thead .sorting_desc:hover:before,
#operator-store-visits-table.dataTable > thead .sorting_desc_disabled:hover:before,
#operator-store-visits-table.dataTable > thead .sorting_asc:before,
#operator-store-visits-table.dataTable > thead .sorting_asc_disabled:before,
#operator-store-visits-table.dataTable > thead .sorting_desc:before,
#operator-store-visits-table.dataTable > thead .sorting_desc_disabled:before {
  right: 1em;
  content: "\2191";
}

#operator-store-visits-table.dataTable > thead .sorting:hover:after,
#operator-store-visits-table.dataTable > thead .sorting_asc:hover:after,
#operator-store-visits-table.dataTable > thead .sorting_asc_disabled:hover:after,
#operator-store-visits-table.dataTable > thead .sorting_desc:hover:after,
#operator-store-visits-table.dataTable > thead .sorting_desc_disabled:hover:after,
#operator-store-visits-table.dataTable > thead .sorting_asc:after,
#operator-store-visits-table.dataTable > thead .sorting_asc_disabled:after,
#operator-store-visits-table.dataTable > thead .sorting_desc:after,
#operator-store-visits-table.dataTable > thead .sorting_desc_disabled:after {
  right: 0.5em;
  content: "\2193";
}

.tooltipster-base {
  @extend .simple-scroll;
  max-height: 50vh;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu > .dropdown-submenu-trigger {
  position: relative;

  &:after {
    display: block;
    position: absolute;
    content: "\f0da";
    font-weight: 900;
    right: 15px;
    color: #B5B5C3;
    font-family: "Font Awesome 6 Pro";
  }
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: 0;
  transform: translateX(-100%);
}

.bg-aliceblue {
  background-color: aliceblue;
}

.ant-smaller-rows {
  .ant-table-tbody > tr:not(.ant-table-measure-row) > td,
  .ant-table-thead > tr:not(.ant-table-measure-row) > th {
    height: 5px;
    padding: 2px 3px !important;
  }
}

.antd-custom-colorpicker.ant-color-picker-trigger {
  width: 100%;

  .ant-color-picker-color-block {
    width: 100%;
  }
}

#quick-invite-modal {
  @media (max-width: 992px) {
    .mobile-card-container {
      margin-bottom: 30px;
      justify-content: center;
      display: flex;
      .box {
        padding: 0 2px;
        margin: 0;
      }
    }
    .mobile-card-container, .mobile-body-container {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .mobile-card {
    position: relative;
    margin: 0;
    border-radius: 32px;
    background-color: #FFF;
    -webkit-box-shadow: 8px 16px 32px rgb(35 58 79 / 35%), 20px 30px 80px rgb(73 73 93 / 40%);
    -moz-box-shadow: 8px 16px 32px rgba(35, 58, 79, 0.35), 20px 30px 80px rgba(73, 73, 93, 0.4);
    box-shadow: 8px 16px 32px rgb(35 58 79 / 35%), 20px 30px 80px rgb(73 73 93 / 40%);
    padding: 0;
    z-index: 100;
    width: 236px;
    height: 480px;
    overflow: hidden;
    outline: 5px solid #f7f7f780;
    float: left;
  }

  .mobile-main-image {
    width: 100px;
    height: 100px;
    background-image: url('../../../public/lionwheel-logo-blue.png');
    background-size: cover;
    background-position: top center;
    margin: 96px auto 36px auto;
    background-color: #06122F;
    border-radius: 16px;
    color: #FFF;
    -webkit-box-shadow: 3px 4px 12px rgba(127, 127, 142, 0.4);
    -moz-box-shadow: 3px 4px 12px rgba(127, 127, 142, 0.4);
    box-shadow: 3px 4px 12px rgb(127 127 142 / 40%);
    position: relative;
    z-index: 2;
  }

  .mobile-app-title {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    font-family: "Source Sans Pro", Helvetica, serif;
    line-height: 16px;
    margin-bottom: 8px;
    color: #9EA2A7;
    letter-spacing: 0.4px;
    text-align: center;
  }

  .mobile-card-title {
    font-size: 20px;
    font-weight: 600;
    font-family: "Source Sans Pro", Helvetica, serif;
    line-height: 24px;
    margin-bottom: 8px;
    color: #06122F;
    text-align: center;
  }

  .buttons-1 {
    a {
      padding: 0;
      width: 100%;
    }
  }

  .buttons-2 {
    a {
      width: 100%;
    }
  }

  .header-title {
    font-size: 36px;
    font-weight: 600;
    font-family: "Source Sans Pro", Helvetica, serif;
    line-height: 40px;
    margin: 24px 0 24px 0;
    color: #06122F;
    text-align: center;
  }

  .main-image-qr {
    text-align: center;
  }
}

@media (max-width: 576px) {
  .d-xs-none { display: none !important; }
}

.ant-table-wrapper.ant-table-h-100 .ant-spin-nested-loading, .ant-spin-container, .ant-table, .ant-table-container, .ant-table-body {
  height: 100%;
}

.alert[data-notify] {
  z-index: 1500 !important;
}

.marker-addition {
  position: absolute;
  height: 25px;
  width: 25px;
  top: 0;
  right: 0;
  transform: translateX(50%) translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.25);
  border: 1px solid #bbb;
  border-radius: 50%;
}

.underline {
  border-bottom: 1px solid #3699FF;
  padding-bottom: 3px;
}

.btn-mobile-search {
  position: absolute;
  right: 106px;
  top: 18px;
}

.line-item-border { border: 1px solid #EBEDF3 !important; }

input[type=number].order-item-quantity-input::-webkit-inner-spin-button,
input[type=number].order-item-quantity-input::-webkit-outer-spin-button {
  height: 40px;
  margin-right: -10px;
}

.item-check-picked {
  color: #5cb85c
}

.bg-success-picked {
  background: #DEF0DE;
  border: 1px solid #5cb85c !important;
}

.item-check-partially_picked {
  color: orange;
}

.bg-success-partially_picked {
  background: #FFE4B2;
  border: 1px solid orange !important;
}

.select2-dropdown.select2-with-unassign-option {
  z-index: 20000;
  .select2-results__option:nth-child(2) {
    background: red;
    color: white;
  }
}

.select2-with-unassign-option-groupped {
  z-index: 20000;
  .select2-results__option:nth-child(2) {
    .select2-results__option {
      background: red;
      color: white;
    }
  }
}

.table-bordered.order-item-table {
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 10px !important;
}

.onboarding-action-checked {
  background-color: #E1F0FF;
}

.city-pricing-cell {
  border: none;
  outline: none;
  z-index: 1;
}

.table.city-price-mapping-table td.active {
  border: 2px solid #3699FF;
}

.cities-table-container {
  position: relative;
  max-height:  650px;
  overflow: scroll;
}

.table.city-price-mapping-table thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}

.table.city-price-mapping-table th:first-child,
.table.city-price-mapping-table td:first-child {
  position: sticky;
  left: 0;
  z-index: 3;
  background-color: #EBEDF3;
}

.cities-table-container::-webkit-scrollbar {
  width: 0.45rem;
  height: 0.45rem;
}

.cities-table-container::-webkit-scrollbar-thumb {
  background-color: rgba(27, 27, 27, 0.4);
  border-radius: 3px;
}

.city-price-mapping-table th .delete-city-btn {
  display: none;
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  cursor: pointer;
}

th:hover .delete-city-btn {
  display: inline-block;
}

.table-rounded-corners {
  border-radius: 0.42rem;
  overflow: hidden;
}

.table-rounded-corners th:first-child {
  border-top-left-radius: 0.42rem;
}

.table-rounded-corners th:last-child {
  border-top-right-radius: 0.42rem;
}

.table-rounded-corners tr:last-child td:first-child {
  border-bottom-left-radius: 0.42rem;
}

.table-rounded-corners tr:last-child td:last-child {
  border-bottom-right-radius: 0.42rem;
}

.panel-view-nav-tabs {
  .nav-link.active, .nav-link:hover:not(.disabled) {
    border-bottom: 2px solid #3699FF !important;
  }
}

.left-placeholder::placeholder {
  text-align: left;
}

.panel-view-tabs .badge.badge-light-primary {
  padding: 4px 6px !important;
}

.onboarding-table-container {
  position: relative;
  max-height:  750px;
  overflow: scroll;
}

.onboarding-table-container::-webkit-scrollbar {
  width: 0.45rem;
  height: 0.45rem;
}

.onboarding-table-container::-webkit-scrollbar-thumb {
  background: rgb(117, 116, 116);
}

.table.sticky-header-table thead th {
  position: -webkit-sticky;
  position: sticky !important;
  top: 0;
  z-index: 2;
  background-color: #EBEDF3;
}

.editable-wrapper {
  line-height: 25px;

  .form-control.form-control-editable {
    height: 25px;
    padding: 2px 8px;
  }

  .input-group.input-group-editable {
    .form-control {
      height: 25px;
      padding: 2px 8px;
    }
    .input-group-text {
      height: 25px;
    }
  }

  .editable-loader {
    position: absolute;
    left: calc(100% + 3px);
    top: 20%;
  }
}

.company-bulk-update-row {
  position: relative;
  padding-right: 35px;
  transition: 0.5s ease;
  border-radius: 7px;

  &:disabled {
    background-color: #f3f3f3;
  }

  .bulk-update-enable {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.resizable-horizontal {
  display: flex;
  flex-direction: row;
  overflow: hidden;

  .resizable-left {
    flex: 1 1 auto;
    width: 100%;
    min-width: 30%;
  }

  .resizable-right {
    position: relative;
    flex: 0 0 auto;

    @media(max-width: 992px) {
      &.d-none.d-lg-flex {
        min-width: 0;
      }
    }
  }
}

.text-grey {
  color: #4E5D6B;
}

.text-light-grey {
  color: #7B8793;
}

.letter-spacing-1px {
  letter-spacing: 1px;
}

@media(max-width: 992px) {
  .daily-route-plan-content {
    height: auto !important;
  }
}

.route-summary-grid {
  display: grid;
  gap: 15px;
  padding: 1.25rem 0;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}

.route-optimize-grid-row-1,
.route-optimize-grid-row-2 {
  display: grid;
  gap: 10px;
}

.route-optimize-grid-row-1 {
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  margin-bottom: 10px;
}

.route-optimize-grid-row-2 {
  grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
  align-items: end;
}

.select2-multiple-fixed-height {
  @extend .simple-scroll;

  .form-control {
    max-height: 36px;

    option {
      visibility: hidden;
    }
  }

  .select2-selection--multiple {
    max-height: 36px;
    overflow-y: auto;
    border-color: #b9b9b9 !important;

    .select2-selection__rendered {
      max-height: 36px;
      margin-bottom: 0;
    }
  }
}

.active-ant-row {
  td {
    background-color: aliceblue !important;
  }
}

/* We have got an issue with chrome browser on Macbook - it does not always shows the scrollbar */
/* This will always show the scrollbar */
::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 7px;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.unhandled-doc {
  color: #fff !important;
  background-color: red !important;
  border-color: red !important;

  i { color: #fff !important; }
}

.handled-doc {
  color: #fff !important;
  background-color: $completed-status-color !important;
  border-color: $completed-status-color !important;

  i { color: #fff !important; }
}

.map-layer-switch {
  position: absolute;
  right: 10px;
  bottom: 25px;
  z-index: 1;
  width: 50px;
  height: 50px;
  background-color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;

  i {
    color: black;
  }
}

.line-height-3rem { line-height: 3rem; }

.geocode-results-grid {
  display: grid;
  gap: 15px;
  padding: 1.25rem 0;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));

  @media(max-width: 568px) {
    display: block;
  }
}

.border-radius-5 { border-radius: 5px; }

.custom-width {
  width: 50%;

  @media (min-width: 768px) {
    width: 38%;
  }
}

#onboarding-modal {
  .modal-dialog {
    margin-top: 5vh;
    @media (max-width: 576px) {
      margin-top: 6px;
    }
  }
}

.scroll-to-top {
  cursor: pointer;
  position: fixed;
  height: 60px;
  width: 60px;
  border: 1px solid #3699FF;
  bottom: 20px;
  right: 86px;
  border-radius: 100% !important;
  z-index: 1;
}

.swal2-checkbox .swal2-label {
  &::before, &::after {
    top: 50%;
    transform: translateY(-50%);
  }
}

.statistic-chart-card-l {
  padding: 0 5px 0 10px;
}

.statistic-chart-card-r {
  padding: 0 5px 0 10px;
}

.new-task-form {
  .inline-item {
    margin-bottom: 5px;
    display: inline-block;
    margin-right: 10px;
  }
}

.integration-platforms-fieldset[disabled] {
  .btn.btn-primary {
    color: #FFFFFF;
    background-color: #3699FF;
    border-color: #3699FF;
    pointer-events: none;
    opacity: 0.6;
    box-shadow: none;
  }
}

.breadcrumb-separator {
  color: #6c757d !important;
  font-size: 1.75rem;
}

.visits-collapse {
  .ant-collapse-header {
    padding: 0!important;
    display: flex!important;
    align-items: center!important;
  }
}

.bg-yellow {
  background-color: #ffff00 !important;
}

.modules {
  .nav.nav-pills .nav-link {
    color: #7E8299;
  }
}

.map-icon-circle-parent {
  border-radius: 50%;
}

.map-icon-circle {
  border-radius: 50%;
  opacity: 0.3;
  background-color: blue;
  border: 1px solid blue;
}

.map-gray-tiles .leaflet-pane.leaflet-tile-pane {
  filter: grayscale(0.5);
}

.range-slider {
  --_height: 5px;
  --_thumb-size: calc(var(--_height) * 4);
  position: relative;
  width: 100%;
  display: grid;
  margin-bottom: calc(var(--_thumb-size) / 2);

  .range-labels {
    display: flex;
    justify-content: space-between;

    padding-bottom: 10px;
    .range-label {
      display: inline-flex;
      font-size: 16px;
      color: #000;
      line-height: 1;

      &-start {
        align-self: flex-start;
      }
      &-end {
        align-self: flex-end;
      }
    }
  }

  .range-title-label {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
    line-height: 14px;
    font-weight: 700;
  }

  .track-wrapper {
    position: relative;
    z-index: 1;
    height: var(--_height);
    margin-bottom: var(--_thumb-size);
    display: grid;
    align-items: center;
    margin: 0 calc(var(--_thumb-size) / 2);

    .track {
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: 5px;
      background-color: #E1F0FF;
    }

    .range-between {
      position: absolute;
      z-index: 2;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: 5px;
      background-color: #3699FF;
    }

    .thumb {
      --_shadow-sizer: 2;
      --_shadow-size: calc(var(--_thumb-size) * var(--_shadow-sizer));
      --_shadow-color: #3699FF;
      --_shadow-opacity: 0;
      position: absolute;
      z-index: 3;
      width: var(--_thumb-size);
      height: var(--_thumb-size);
      background-color: #3699FF;
      border-radius: 50%;
      transition: box-shadow 0.3s ease-in-out;

      &::before {
        content: '';
        pointer-events: none;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        background-color: var(--_shadow-color);
        width: var(--_shadow-size);
        height: var(--_shadow-size);
        border-radius: inherit;
        opacity: var(--_shadow-opacity);
      }

      &.left {
        left: 0;
        transform: translate(calc(var(--_thumb-size) / -2), 0px);
      }

      &.right {
        right: 0;
        transform: translate(calc(var(--_thumb-size) / 2), 0px);
      }

      &.hover {
        --_shadow-opacity: .2;
      }

      &.active {
        --_shadow-opacity: .4;
        --_shadow-sizer: 4;
      }
    }
  }

  input[type='range'] {
    position: absolute;
    pointer-events: none;
    -webkit-appearance: none;
    z-index: 2;
    height: var(--_height);
    width: 100%;
    opacity: 0;
    cursor: pointer;
    bottom: 0;
  }

  input[type='range']::-webkit-slider-thumb {
    pointer-events: all;
    width: var(--_thumb-size);
    height: var(--_thumb-size);
    border-radius: 0;
    border: 0 none;
    background-color: #3699FF;
    -webkit-appearance: none;
  }

  input[type='range']::-moz-range-thumb {
    pointer-events: all;
    width: var(--_thumb-size);
    height: var(--_thumb-size);
    border-radius: 0;
    border: 0 none;
    background-color: #3699FF;
    -webkit-appearance: none;
  }
}
