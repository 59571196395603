$unassigned-status-color: #ed6b75bf;
$assigned-status-color: #f1c40fbf;
$active-status-color: #36c6d3bf;
$failed-status-color: #6d3030;
$final-failed-status-color: #000000;
$in-transfer-status-color: #f70268;
$completed-status-color: #337ab7;
$roundtrip-delivered-status-color: #800080bf;
$canceled-status-color: #bac3d0bf;
$in-inventory-status-color: #bf5a08bf;
$out-inventory-status-color: #ffa800;

$aside-width: 115px;
